export default function ButtonSmall({
  type = "normal",
  title,
  children,
  ...otherprops
}) {
  let bg_col = "bg-blue-600";
  let hl_col = "bg-blue-800";
  if (type === "warning") {
    bg_col = "bg-purpule-500";
    hl_col = "bg-purple-800";
  }
  if (type === "success") {
    bg_col = "bg-teal-500";
    hl_col = "bg-teal-800";
  }

  if (type === "danger") {
    bg_col = "bg-pink-500";
    hl_col = "bg-pink-800";
  }
  let cln =
    bg_col +
    " " +
    hl_col +
    "  text-white font-bold uppercase text-xs px-4 py-2 rounded-3xl shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150";
  return (
    <button className={cln} type="button" {...otherprops}>
      {children}
      {title}
    </button>
  );
}
