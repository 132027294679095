export default function InputRegular(props) {
  return (
    <div className="mb-3 pt-0">
      <input
        type="text"
        placeholder="Placeholder"
        className="px-3 py-3 placeholder-gray-400 text-gray-50 relative  bg-indigo-600 rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
        {...props}
      />
    </div>
  );
}
