import React, { useReducer, createContext } from "react";

import Login from "./views/Login";
import Teller from "./views/Teller";
import RealmAdmin from "./views/RealmAdmin";
import Navbar from "./components/Navbar";
import FooterSmall from "./components/FooterSmall";

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (userSessionState, action) => {
  switch (action.type) {
    case "LOGIN":
      //
      return {
        ...userSessionState,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...userSessionState,
        isAuthenticated: false,
        user: null,
      };
    default:
      return userSessionState;
  }
};

function App() {
  const [userSessionState, dispatch] = useReducer(reducer, initialState);

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        userSessionState,
        dispatch,
      }}
    >
      <Navbar navigator onLogout={handleLogout} />
      {!userSessionState?.isAuthenticated && <Login />}
      {userSessionState?.isAuthenticated && (
        <div className="flex flex-col bg-gradient-to-r from-sky-950 to-blue-950 pt-4 mt-4 px-4 lg:px-8 overflow-auto h-screen w-full">
          <h1 className="flex font-extrabold text-gray-300 items-center justify-center">
            Monime Collections v1.01.a
          </h1>
          <div className="flex content-center items-center justify-center overflow-auto">
            {userSessionState.user.role === "realm" ? (
              <RealmAdmin />
            ) : (
              <Teller />
            )}
          </div>
        </div>
      )}
      <FooterSmall absolute />
    </AuthContext.Provider>
  );
}

export default App;
