import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert.js";
import FooterSmall from "../components/FooterSmall.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
import Navbar from "../components/Navbar.js";

import { AuthContext } from "../App.js";
import { useApi } from "../data/DummyData.js";

export default function Login() {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [realm, setRealm] = useState("");
  const [isWorking, setWorking] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const api = useApi();
  const nav = useNavigate();
  const toast = useRef();

  const handleSubmit = () => {
    // api works here to submit and get the answers from the back end ........
    if (realm.length < 2 || loginId.length < 2 || password.length < 4) {
      toast.current.show({
        severity: "error",
        summary: "Please fill in all the details ....",
      });
      return;
    }
    setWorking(true);
    api
      .signIn({ loginId, password, realm })
      .then((r) => {
        let user = r.data;
        if (user) {
          console.info("Login success, Calling Dispatcher ....");
          dispatch({
            type: "LOGIN",
            payload: {
              user,
              token: "34146f00-2a8d-11ef-810f-42010a3c0202",
            },
          });
          console.info("Dispatcher executed. Redirecting ...");
          nav("/");
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary:
            "Sorry we could not sign you in with provided credentials ...",
          life: 3000,
        });
      })
      .finally(() => {
        setWorking(false);
      });
  };
  const realmBlurred = (evt) => {
    if (evt.code === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <Alert ref={toast} />
      <Navbar />
      <main>
        <section className="absolute w-full h-full">
          <div
            className="absolute top-0 w-full h-full bg-gradient-to-r from-sky-950 to-blue-950"
            style={{
              backgroundImage: "assets/img/register_bg_2.png",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-indigo-950 border-0">
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-gray-900 text-center mb-3 font-bold">
                      <p>Sign in</p>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-300 text-xs font-bold mb-2"
                        htmlFor="loginId"
                      >
                        Login Id
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Login Id"
                        value={loginId}
                        onChange={(e) => setLoginId(e.target.value)}
                        style={{ transition: "all .15s ease" }}
                        id="loginId"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-300 text-xs font-bold mb-2"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="border-0 px-3 py-3 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ transition: "all .15s ease" }}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-300 text-xs font-bold mb-2"
                        htmlFor="realm"
                      >
                        REALM
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-gray-500 uppercase text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="REALM"
                        value={realm}
                        onChange={(e) => setRealm(e.target.value)}
                        style={{ transition: "all .15s ease" }}
                        onKeyDown={realmBlurred}
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        type="button"
                        onClick={handleSubmit}
                        style={{ transition: "all .15s ease" }}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {isWorking && <LoadingSpinner />}
            </div>
          </div>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
