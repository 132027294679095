import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import App from "./App";
import Login from "./views/Login";

const AppWrapper = (props) => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route exact path="/auth/login" element={<Login />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
};

export default AppWrapper;
