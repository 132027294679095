import { Spinner } from "../components/Spinner";

export default function LoadingSpinner(props) {
  return (
    <div class="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
      <div class="flex justify-center items-center mt-[50vh]">
        <Spinner />
      </div>
    </div>
  );
}
