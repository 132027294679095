import { useContext, useEffect, useRef, useState } from "react";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
// eslint-disable-next-line
import "rsuite-table/dist/css/rsuite-table.css"; //     "rsuite-table/lib/less/index.less"; // or   // ; //or

import { AuthContext } from "../App";
import { useApi } from "../data/DummyData";

import Alert from "../components/Alert";
import ButtonSmall from "../components/ButtonSmall";
import ConfirmDialog from "../components/ConfirmDialog";
import InputRegular from "../components/InputRegular";
import LoadingSpinner from "../components/LoadingSpinner";

import { Popover } from "../components/Popover";

export default function RealmAdmin(props) {
  const [isWorking, setWorking] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [isAdding, setAdding] = useState(false);

  const [realm, setRealm] = useState({});
  const [users, setUsers] = useState([]);
  const [current, setCurrent] = useState({});
  const [compositeMessage, setCompositeMessage] = useState({});
  const { userSessionState } = useContext(AuthContext);

  const api = useApi();
  const dt = useRef();
  const toast = useRef();

  useEffect(() => {
    setWorking(true);
    api
      .getRealmUsers(userSessionState?.user?.realm)
      .then((r) => {
        let users = [];
        users = r.data;
        setUsers(users);
        api
          .getRealms()
          .then((r) => {
            let datas = [];
            datas = r.data;
            let data = datas.filter((elt) => elt.id === users[0].realm)[0];
            setRealm(data);
          })
          .catch((e) => {
            console.info("No data");
          });
      })
      .catch((e) => {
        console.info("No data!");
      })
      .finally(() => {
        setWorking(false);
      });

    // eslint-disable-next-line
  }, []);

  // handlers

  const handleAccept = () => {
    console.info("Handle accept from Comfirm ::: " + compositeMessage.id);
    if (compositeMessage.id === 1) {
      // reset
      setWorking(true);
      api
        .resetRealmUser(realm.realmLabel, current.id)
        .then((r) => {
          toast.current.showSuccess(
            "Access code of " + current.fullName + " successfully reset!"
          );
        })
        .catch((e) => {
          toast.current.showError(
            "Could not reset access code of " + current.fullName
          );
        })
        .finally(() => {
          setWorking(false);
        });
    } else {
      // revoke
      setWorking(true);
      api
        .revokeRealmUser(realm.realmLabel, current.id)
        .then((r) => {
          toast.current.showSuccess("Successfully revoked " + current.fullName);
          let _users = users.filter((user) => user.id !== current.id);
          setUsers(_users);
        })
        .catch((e) => {
          console.info("Error revoking user: " + JSON.stringify(e));
          toast.current.showError("Error revoking " + current.fullName);
        })
        .finally(() => {
          setShowDialog(false);
          setWorking(false);
        });
    }
    setConfirmDialog(false);
    setCompositeMessage(null);
  };

  const handleAddNew = (evt) => {
    setCurrent({
      id: "",
      fullName: "",
      realm: realm.id,
      password: "",
      role: "teller",
      loginId: "",
      cell: "",
    });
    setAdding(true);
    setShowDialog(true);
    evt.preventDefault();
  };

  const handleClosePopOver = (evt) => {
    if (isAdding) {
      // do some validations ....
      // and push to persistence
      if (!current.loginId.length < 1) {
        handleSave();
      }
    } else {
      // edit. We sync in remote persistence
      api
        .setRealmUser(realm.realmLabel, current)
        .then((r) => {
          console.info("Success");
        })
        .catch((e) => {
          console.info("Error: " + JSON.stringify(e));
        });
    }
    setShowDialog(false);
    if (confirmDialog) {
      setConfirmDialog(false);
    }
  };

  const handleEdits = (evt) => {
    const { name, value } = evt.target;
    let _current = { ...current };
    _current[`${name}`] = value;
    setCurrent(_current);
    if (!isAdding) {
      // only in Edit mode ....
      let _users = [...users];
      _users.forEach((user) => {
        if (user.id === _current.id) {
          // edit it
          user.fullName = _current.fullName;
          user.cell = _current.cell;
        }
      });
      setUsers(_users);
    }
  };

  const handleReject = () => {
    setConfirmDialog(false);
    setCompositeMessage(null);
  };

  const handleReset = () => {
    setCompositeMessage({
      id: 1,
      message:
        "Are you sure to reset access code of " + current?.fullName + "?",
    });
    if (isAdding) {
      setAdding(false); // should not happen
    }
    setConfirmDialog(true);
  };

  const handleRevoke = () => {
    setCompositeMessage({
      id: 2,
      message: "Are you sure to revoke access of " + current?.fullName + "?",
    });
    if (isAdding) {
      setAdding(false); //should not happen
    }
    setConfirmDialog(true);
  };

  const handleRowSelection = (rowData) => {
    setCurrent(rowData);
    if (!showDialog) {
      setShowDialog(true);
    }
    if (isAdding) {
      setAdding(false);
    }
  };

  const handleSave = () => {
    let _current = { ...current };
    let _users = [...users];
    api
      .addRealmUser(realm.realmLabel, _current)
      .then((r) => {
        console.info("Success!!! Add user ...");
      })
      .catch((e) => {
        console.info("Error::: add user:: " + JSON.stringify(e));
      }); // back end to save ....
    _users.push(_current);
    setUsers(_users);
  };

  const handleScroll = (x, y) => {
    // This should print 200 under normal conditions
    //console.log(data.length);
  };

  // sub components

  const PoperContent = () => (
    <>
      <div className="w-full">
        <label
          className="block uppercase text-gray-400 text-xs font-bold mb-2 px-2"
          htmlFor="loginId"
        >
          Login Id
        </label>
        <InputRegular
          id="loginId"
          name="loginId"
          placeholder="Login Id"
          readOnly={!isAdding}
          value={current?.loginId}
          onChange={handleEdits}
        />
      </div>
      <div className="w-full">
        <label
          className="block uppercase text-gray-400 text-xs font-bold mb-2 px-2"
          htmlFor="cell"
        >
          Cell No.
        </label>
        <InputRegular
          id="cell"
          name="cell"
          placeholder="Cell No."
          value={current?.cell}
          onChange={handleEdits}
        />
      </div>
      <div className="w-full">
        <label
          className="block uppercase text-gray-400 text-xs font-bold mb-2 px-2"
          htmlFor="fullName"
        >
          Full name
        </label>
        <InputRegular
          id="fullName"
          name="fullName"
          placeholder="Full name"
          value={current?.fullName}
          onChange={handleEdits}
        />
      </div>
    </>
  );

  const PopFooter = () => (
    <>
      {!isAdding && (
        <>
          <ButtonSmall title="Reset" type="success" onClick={handleReset}>
            <i className="pi pi-sign-in pr-2"></i>
          </ButtonSmall>
          {current?.role !== "realm" && (
            <ButtonSmall title="Revoke" type="danger" onClick={handleRevoke}>
              <i className="pi pi-trash pr-2"></i>
            </ButtonSmall>
          )}
        </>
      )}
    </>
  );

  // Render
  return (
    <div className="flex flex-col content-center items-center justify-center overflow-hidden max-h-96 rounded-xl shadow px-3 py-5 mt-6 mb-3 w-2/3  ">
      <Alert ref={toast} />

      <div className="flex flex-col content-center items-center justify-center h-full w-full mb-4">
        <p className="text-white font-bold mt-2">{realm?.realmName}</p>
        <div id="tableContainer" className="flex flex-col w-full">
          <div className="w-full mt-4 mb-2 pt-4">
            <div className="flex flex-row-reverse">
              <ButtonSmall title="Add" type="warning" onClick={handleAddNew}>
                <i className="pi pi-plus"></i>
              </ButtonSmall>
            </div>
          </div>

          <Table
            id="userTable"
            data={users}
            affixHorizontalScrollbar
            height={380}
            width={"100%"}
            className="mx-2"
            onRowClick={(data) => {
              handleRowSelection(data);
            }}
            onScroll={handleScroll}
            ref={dt}
          >
            <Column width={200} sortable fixed>
              <HeaderCell className="pl-2">Login ID</HeaderCell>
              <Cell dataKey="loginId" />
            </Column>

            <Column flexGrow={3} sortable>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="fullName" />
            </Column>

            <Column flexGrow={2} sortable>
              <HeaderCell>Cell</HeaderCell>
              <Cell dataKey="cell" />
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Role</HeaderCell>
              <Cell dataKey="role" />
            </Column>
          </Table>
          {showDialog && (
            <Popover
              FooterComponent={PopFooter}
              title="Users"
              subtitle={current.fullName}
              ContentComponent={PoperContent}
              onCloseRequest={handleClosePopOver}
            />
          )}
          {confirmDialog && (
            <ConfirmDialog
              title="Confirm"
              message={compositeMessage.message}
              onAccept={handleAccept}
              onReject={handleReject}
            />
          )}
          {isWorking && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
}
