export default function InputSmall(props) {
  return (
    <div className="mb-3 pt-0">
      <input
        type="text"
        placeholder="Placeholder"
        className="px-2 py-1 placeholder-gray-500 text-gray-800 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
        {...props}
      />
    </div>
  );
}
