export const Popover = ({
  title,
  subtitle,
  FooterComponent,
  ContentComponent,
  onCloseRequest,
}) => {
  const handleCloseRequest = (evt) => {
    if (onCloseRequest) {
      onCloseRequest(evt);
    }
  };

  return (
    <div
      className="relative z-10 "
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      {/**<!-- Background backdrop, show/hide based on slide-over state. -->*/}
      <div className="fixed inset-0"></div>

      <div className="fixed inset-y-4 overflow-hidden">
        <div className="absolute inset-y-2 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-2 right-0 flex max-w-full pl-10 sm:pl-16">
            {/*<!--
            Slide-over panel, show/hide based on slide-over state.
  
            Entering: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-full"
              To: "translate-x-0"
            Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
              From: "translate-x-0"
              To: "translate-x-full"
          -->*/}
            <div className="pointer-events-auto w-screen max-w-md">
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl mt-4">
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-indigo-700 mt-4 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between mt-1">
                      <h2
                        className="text-base font-semibold leading-6 text-white"
                        id="slide-over-title"
                      >
                        {title}
                      </h2>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={(evt) => handleCloseRequest(evt)}
                          className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="absolute -inset-2.5"></span>
                          <span className="sr-only">Close panel</span>
                          <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-indigo-300">{subtitle}</p>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between  bg-indigo-500 border-t border-white h-full">
                    <div className="h-fit">{ContentComponent()}</div>
                  </div>
                </div>
                {/** sticky footer */}
                <div className="flex flex-shrink-0 justify-end px-4 py-4  bg-indigo-700">
                  {FooterComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
