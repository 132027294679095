import React from "react";

export default function FooterSmall(props) {
  return (
    <>
      <footer
        className={
          (props.absolute
            ? "absolute w-full bottom-0 bg-indigo-950"
            : "relative") + " pb-6"
        }
      >
        <div className="container mx-auto px-4">
          <hr className="mb-6 border-b-1 border-gray-700" />
          <div className="flex items-center justify-center">
            <div className="text-sm text-white font-semibold py-1">
              Copyright © 2023-{new Date().getFullYear()}{" "}
              <a
                href="https://www.monime.io"
                className="text-white hover:text-gray-400 text-sm font-semibold py-1"
              >
                Monime Inc
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
