// Would have it as prop or part of session context. Will decide later on that ...
import axios from "axios";

const ebetDevices = [
  {
    id: "100100",
    name: "Sundu mantie Marrah",
    meta: "72255119",
  },
  {
    id: "100101",
    name: "Ibrahim Mustapha",
    meta: "72246048",
  },
  {
    id: "100102",
    name: "Mohamed hawa Samura",
    meta: "75919469",
  },
  {
    id: "100103",
    name: "Satta Turay",
    meta: "72484284",
  },
  {
    id: "100104",
    name: "Hassan Samura",
    meta: "76277865",
  },
  {
    id: "100105",
    name: "Teti Samura",
    meta: "73349011",
  },
  {
    id: "100106",
    name: "Alie Sulaiman Turay",
    meta: "78741223",
  },
  {
    id: "100107",
    name: "Fela Samura",
    meta: "76540871",
  },
  {
    id: "100108",
    name: "Kota Turay",
    meta: "73059434",
  },
  {
    id: "100109",
    name: "Lansaina F. Samura",
    meta: "72525020",
  },
  {
    id: "100110",
    name: "Finnah Marrah",
    meta: "76271200",
  },
  {
    id: "100111",
    name: "Sanna Turay",
    meta: "75718607",
  },
  {
    id: "100112",
    name: "Mamud Samura",
    meta: "72383707",
  },
  {
    id: "100113",
    name: "Ibrahim Kamara",
    meta: "79853824",
  },
  {
    id: "100114",
    name: "Tenneh Samura",
    meta: "76576673",
  },
  {
    id: "100115",
    name: "Abu Koroma",
    meta: "75025628",
  },
  {
    id: "100116",
    name: "Demba Samura",
    meta: "74713979",
  },
  {
    id: "100117",
    name: "Shekuba Turay",
    meta: "78006461",
  },
  {
    id: "100118",
    name: "Yere Samura",
    meta: "75745623",
  },
  {
    id: "100119",
    name: "Sagor Turay",
    meta: "76257516",
  },
  {
    id: "100120",
    name: "Isata Turay",
    meta: "76263871",
  },
  {
    id: "100121",
    name: "Suma Samura",
    meta: "72913483",
  },
  {
    id: "100122",
    name: "Salifu Samura",
    meta: "76859828",
  },
  {
    id: "100123",
    name: "Hassan Kamara",
    meta: "73160248",
  },
  {
    id: "100124",
    name: "Mohammed Samura",
    meta: "75778388",
  },
  {
    id: "100125",
    name: "Kaninnie Samura",
    meta: "74840627",
  },
  {
    id: "100126",
    name: "Fatmata Turay",
    meta: "73572698",
  },
  {
    id: "100127",
    name: "Manju Turay",
    meta: "72625929",
  },
  {
    id: "100128",
    name: "Tenneh Samura",
    meta: "78504272",
  },
  {
    id: "100129",
    name: "Tagie Samura",
    meta: "76187893",
  },
  {
    id: "100130",
    name: "Bintu Samura",
    meta: "75627205",
  },
  {
    id: "100131",
    name: "Fatmata Jalloh",
    meta: "72240716",
  },
  {
    id: "100132",
    name: "Foday M. Turay",
    meta: "76257164",
  },
  {
    id: "100133",
    name: "Gbae Kamara",
    meta: "75243457",
  },
  {
    id: "100134",
    name: "Mohamed Kabobodie Turay",
    meta: "76771610",
  },
  {
    id: "100135",
    name: "Sunkarie Samura",
    meta: "78532003",
  },
  {
    id: "100136",
    name: "Finder Samura",
    meta: "72235248",
  },
  {
    id: "100137",
    name: "Manso Samura",
    meta: "76270539",
  },
  {
    id: "100138",
    name: "Isata Turay",
    meta: "76258704",
  },
  {
    id: "100139",
    name: "Phoreh Turay",
    meta: "76243500",
  },
  {
    id: "100140",
    name: "Sana Samura",
    meta: "76225364",
  },
  {
    id: "100141",
    name: "Jeneba Samura",
    meta: "79803792",
  },
  {
    id: "100142",
    name: "Manty Kamara",
    meta: "75139285",
  },
  {
    id: "100143",
    name: "Meseh Samura",
    meta: "75025346",
  },
  {
    id: "100144",
    name: "Finda Samura",
    meta: "72253574",
  },
  {
    id: "100145",
    name: "Suliman Kamara",
    meta: "76830125",
  },
  {
    id: "100146",
    name: "Forreh Kamara",
    meta: "74205781",
  },
  {
    id: "100147",
    name: "Mohamed Samura",
    meta: "74057613",
  },
  {
    id: "100148",
    name: "SAIO SAMURA",
    meta: "74740988",
  },
  {
    id: "100149",
    name: "Hassan Kamara",
    meta: "72253591",
  },
];
const BASE_URL = "https://ullebar-sl.net/api/v1";

export function useApi() {
  const addCollection = async (paylod) => {
    // perform back addition
    console.info("useApi.js ::: Payload:: " + JSON.stringify(paylod));
    const resp = await axios.post(BASE_URL + "/collections", { ...paylod });
    return resp;
  };

  const addRealmUser = async (realm, user) => {
    return await axios.post(BASE_URL + "/realms/" + realm + "/users", {
      ...user,
    });
  };

  const getSchemas = async () => {
    return await axios.get(BASE_URL + "/collectors");
  };

  const getIdVerification = (scheme, id) => {
    let result = null;
    console.info("useApi.js ::: " + scheme + " :: " + id);
    if (scheme === "ibait") {
      ebetDevices.forEach((element) => {
        if (element.id === id) {
          result = element;
        }
      });
    }
    return result;
  };

  const getRealms = async () => {
    return await axios.get(BASE_URL + "/realms");
  };

  const getRealmUsers = async (realm) => {
    return await axios.get(BASE_URL + "/realms/" + realm + "/users");
  };
  const setRealmUser = async (realm, user) => {
    return await axios.put(BASE_URL + "/realms/" + realm + "/users", {
      ...user,
    });
  };

  const resetRealmUser = async (realm, userId) => {
    return await axios.put(BASE_URL + "/realms/" + realm + "/users/" + userId);
  };

  const revokeRealmUser = async (realm, userId) => {
    return await axios.delete(
      BASE_URL + "/realms/" + realm + "/users/" + userId
    );
  };

  const setRealm = (realm) => {
    //realms.push(...realm);
  };

  const signIn = async (payload) => {
    return await axios.post(BASE_URL + "/auth/signin", { ...payload });
  };

  const validateSchemaId = async (schemaId, id) => {
    return await axios.get(
      BASE_URL + "/collectors/" + schemaId + "/idVerify/" + id
    );
  };

  return {
    addCollection,
    addRealmUser,
    getIdVerification,
    getRealms,
    getRealmUsers,
    getSchemas,
    resetRealmUser,
    revokeRealmUser,
    setRealmUser,
    setRealm,
    signIn,
    validateSchemaId,
  };
}
