import React from "react";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full shadow-lg bg-indigo-950 flex flex-wrap items-center justify-between py-3 px-3">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              href="https://www.monime.io"
            >
              Monime Collections
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i
                className={
                  (props.transparent ? "text-gray-800" : "text-white") +
                  " pi pi-bars"
                }
              ></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="top-nav-bar"
          ></div>
          {props.navigator && (
            <div className="w-full relative flex justify-end lg:w-auto lg:static lg:block lg:justify-end">
              <button
                type="button"
                className="text-white text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase border-0"
                onClick={() => {
                  props.onLogout();
                }}
              >
                Sign out
              </button>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}
