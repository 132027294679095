import { useContext, useEffect, useRef, useState } from "react";

import { AuthContext } from "../App";
import { useApi } from "../data/DummyData";

import Alert from "../components/Alert";
import ConfirmDialog from "../components/ConfirmDialog";
import InputSmall from "../components/InputSmall";
import LoadingSpinner from "../components/LoadingSpinner";
import Select from "react-select";

export default function Teller(props) {
  const [schema, setSchema] = useState(null);
  const [schOptions, setSchOtions] = useState([]);
  const [schSelected, setSchSelected] = useState("");
  const [schemas, setSchemas] = useState([]);
  const [values, setValues] = useState([""]);
  const [postEnabled, setPostEnabled] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const api = useApi();
  const toast = useRef();
  const { userSessionState } = useContext(AuthContext);

  useEffect(() => {
    setWorking(true);
    api
      .getSchemas()
      .then((r) => {
        //console.info("useEffect::: " + JSON.stringify(r));
        let data = r.data;
        setSchemas(data);
        let opts = [];
        opts.push({ id: "none", label: "" });
        data.forEach((elt) => {
          opts.push({ id: elt.cfgPrefix, label: elt.cfgPrefix });
        });
        setSchOtions(opts);
      })
      .catch((e) => {
        toast.current.showError("No collection schemas available to process");
      })
      .finally(() => {
        setWorking(false);
      });
    // eslint-disable-next-line
  }, []);

  const clearPage = (evt) => {
    setWorking(false);
    setPostEnabled(false);
    setSchema(null);
    setValues([""]);
    setSchSelected("");
    setConfirmDialog(false);
  };

  const accept = () => {
    setWorking(true);
    postToDb();
    setWorking(false);
    clearPage();
  };

  const reject = () => {
    clearPage();
  };

  const postToDb = () => {
    const fields = schema.fields;
    let data = [];
    // eslint-disable-next-line
    fields.map((field, index) => {
      if (field?.mandatory || !field?.mandatory) {
        data.push({ name: field.name, value: values[index] });
      }
    });

    let pay = {
      schema: schema.id,
      realm: userSessionState.user,
      data: data,
    };
    api.addCollection(pay);
  };

  const handlePopup = (evt) => {
    setConfirmDialog(true);
  };

  const handleSchemaInput = (val) => {
    const sch = schemas.filter((scheme) => scheme.cfgPrefix === val.id)[0];
    let fields = JSON.parse(sch.IAsk) || [];
    let value = { ...sch };
    value.fields = fields;
    setSchema(value);
    let vals = [];
    fields.forEach((element) => {
      vals.push("");
    });
    setValues(vals);
    setSchSelected(sch.cfgPrefix);
  };

  const handleBlur = (evt) => {
    let name = evt.target.name;
    let value = evt.target.value;
    schema.fields.forEach((element) => {
      if (element.name === name) {
        if (element?.blur) {
          // execute the API abstraction
          let result = api.getIdVerification(schema.cfgPrefix, value);
          if (result) {
            let idx = 0;
            let _vals = [...values];
            schema.fields.forEach((fld) => {
              if (fld?.result) {
                _vals[idx] =
                  fld.name === "responseName" ? result.name : result.meta;
              }
              idx++;
            });
            setValues(_vals);
            setPostEnabled(true);
          } else {
            toast.current.showError("Id not found!");
          }
        }
      }
    });
  };

  const handleInputs = (evt) => {
    let name = evt.target.name;
    const val = evt.target.value.toString();
    const index = schema.fields.findIndex((elt) => elt.name === name);
    let _values = [...values];
    _values[index] = val;
    setValues(_values);
  };

  const RenderSchemaInput = () => {
    return (
      <div className="flex flex-col">
        {schema?.fields.map((field, index) => {
          return (
            <div className="w-full">
              {/**  */}
              <label
                className="block uppercase text-gray-400 text-xs font-bold mb-2"
                htmlFor={field.name}
                key={field.name + "_lbl"}
              >
                {field.label}
              </label>
              {field?.result && (
                <InputSmall
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  placeholder={field.label}
                  readOnly
                  value={values[index]}
                />
              )}
              {field?.mandatory && (
                <InputSmall
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  placeholder={field.label}
                  value={values[index]}
                  onChange={handleInputs}
                  onBlur={handleBlur}
                />
              )}
              {!field?.mandatory && !field?.result && (
                <InputSmall
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  placeholder={field.label}
                  value={values[index]}
                  onChange={handleInputs}
                />
              )}
            </div>
          );
        })}
        <div className="flex flex-col w-full mt-3 text-center">
          <button
            className="bg-blue-600 text-white disabled:bg-blue-300 active:bg-blue-800 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            disabled={!postEnabled}
            onClick={handlePopup}
          >
            Post
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col content-center items-center justify-center bg-indigo-950 overflow-auto min-h-96 h-fit  border-1 rounded-xl shadow px-3 py-5 mt-6 mb-3 w-1/2">
      <Alert ref={toast} />
      <p className="text-white font-bold ">Monime Collect</p>

      <div className="flex pt-2 flex-col content-center items-center h-full w-full mb-4 border-b border-gray-50 ">
        <div className="items-center h-full py-2">
          <label
            className="block uppercase text-gray-400 text-xs font-bold mb-2"
            htmlFor="schema"
          >
            Schema
          </label>
          <Select
            options={schOptions}
            key="schema"
            onChange={handleSchemaInput}
            defaultValue={schSelected}
            id="schema"
            className="my-2 block w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="flex flex-col content-center items-center justify-center overflow-auto h-full w-full mb-4">
        {/** This is my form */}

        <div className="flex flex-col h-full">
          <div className="flex w-full">{schema && RenderSchemaInput()}</div>
        </div>
        {confirmDialog && (
          <ConfirmDialog
            title="Confirm"
            message="Are you sure you want to post this transaction"
            onAccept={accept}
            onReject={reject}
          />
        )}
        {isWorking && <LoadingSpinner />}
      </div>
    </div>
  );
}
