import React, { useEffect, useRef, useState } from "react";
import { ZIndexUtils } from "../Utils/ZIndexUtils";

function Alert(props, ref) {
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const containerRef = useRef(null);

  useEffect(() => {
    return () => {
      ref.current = null;
      clear();
    };
  }, [ref]);

  const ErrorAlert = () => (
    <div
      id="errorbox"
      className="fixed inset-x-0 top-0 flex items-end justify-right px-4 py-6 justify-end"
    >
      <div className="max-w-sm w-full shadow-lg rounded px-4 py-3 rounded relative bg-red-400 border-l-4 border-red-700 text-white">
        <div className="p-2">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm leading-5 font-medium">Error</p>
              <p className="text-sm leading-5 font-medium">{message}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="inline-flex text-white transition ease-in-out duration-150"
                onClick={dismiss}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const SuccessAlert = () => (
    <div
      id="box"
      className="fixed inset-x-0 top-0 flex items-end justify-right px-4 py-6 justify-end"
    >
      <div className="max-w-sm w-full shadow-lg rounded px-4 py-3 relative bg-green-400 border-l-4 border-green-700 text-white">
        <div className="p-2">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm leading-5 font-medium">Success</p>
              <p className="text-sm leading-5 font-medium">{message}</p>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="inline-flex text-white transition ease-in-out duration-150"
                onClick={dismiss}
              >
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const dismiss = () => {
    //return this.parentNode.remove();
    setSuccess(false);
    setError(false);
    setMessage("");
  };

  const showSuccess = (msg = "") => {
    setMessage(msg);
    setSuccess(true);
  };

  const showError = (msg = "") => {
    setMessage(msg);
    setError(true);
  };

  const clear = () => {
    ZIndexUtils.clear(containerRef.current);
  };

  React.useImperativeHandle(ref, () => ({
    props,
    showSuccess,
    showError,
    clear,
    getElement: () => containerRef.current,
  }));

  return (
    <div id="msa-alert" ref={ref}>
      {isError && <ErrorAlert />}
      {isSuccess && <SuccessAlert />}
    </div>
  );
}

export default React.forwardRef(Alert);
